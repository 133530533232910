<template>
    <div class="OnePage page" id="HomePage">
        <WelcomeAnimation />
        <Expertise />
        <div class="ClientsPartners Wrapper-section" id="ClientsPartnersPage">
            <div class="Trigger TriggerTop" id="ClientsPartnersTriggerTop"></div>
            <Clients />
            <Partners />
            <div class="Trigger TriggerBottom" id="ClientsPartnersTriggerBottom"></div>
        </div>
        <LetsConnect />
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'ExpertiseView',
        components: {
            WelcomeAnimation: () => import('@/components/WelcomeAnimation'),
            Expertise: () => import('@/components/Expertise'),
            Clients: () => import('@/components/Clients'),
            Partners: () => import('@/components/Partners'),
            LetsConnect: () => import('@/components/LetsConnect')
        },
        data() {
            return {
                sceneTimer: null,
                scrollMagicTrigger: ['ClientsPartnersTriggerTop'],
                scrollMagicSectionId: ['ClientsPartnersPage']
            };
        },
        computed: {},
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            setBgClass: function (className) {
                store.dispatch('setBgClass', className);
            },
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);
                const SMC = new this.$scrollmagic.Controller();

                for (let i = 0; i < this.scrollMagicSectionId.length; i++) {
                    const scene = new this.$scrollmagic.Scene({
                        triggerElement: `#${this.scrollMagicTrigger[i]}`,
                        triggerHook: 'onEnter'
                    }).setClassToggle(`#${this.scrollMagicSectionId[i]}`, 'visible'); // add class to reveal
                    SMC.addScene(scene);
                }
            }
        }
    };
</script>
